import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const LogoWrapper = styled.span`
  display:inline-block;
  width: 70px;
  margin-top: 0;
  
 
`

const Logo = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                  fluid(maxWidth: 40) {
                      ...GatsbyImageSharpFluid_tracedSVG
                  }
              }
          }
      }
  `)
  return (
    <LogoWrapper>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ maxWidth: "40px" }}
        imgStyle={{ objectFit: "contain" }}
        className={"logo"}
      />
    </LogoWrapper>
  )
}

export default Logo
